import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import HeaderDivider from "../components/HeaderDivider";
import PageHeader from "../components/PageHeader";

const Careers = () => {
  const {
    palette: {
      secondary: { main: secondary },
    },
  } = useTheme();
  return (
    <Box style={{ minHeight: "94vh" }}>
      <PageHeader
        src={"/assets/personnel.jpg"}
        alt="personnel"
        title="Careers"
        sub="Join The Fight"
        backgroundPosition="top center"
      />
      <HeaderDivider />
      <Box style={{ textAlign: "center" }}>
        <Card style={{ backgroundColor: secondary }}>
          <CardContent style={{ display: "flex", justifyContent: "center" }}>
            <Container>
              <Typography>
                Due to COVID-19, Centurion has temporarily issued a company wide
                hiring freeze for the 2020 calendar year. If you would like to
                reach out to us regarding an existing application, please visit
                our <Link to="/contact">Contact page</Link>. <br />
              </Typography>
            </Container>
          </CardContent>
        </Card>
        <Typography style={{ margin: 10 }} variant="h4">
          Open Positions
        </Typography>
        <Card style={{ margin: 10 }}>
          <CardContent style={{ display: "flex", justifyContent: "center" }}>
            <Typography>No open positions at this time. </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
export default Careers;
