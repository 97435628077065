import src from "*.bmp";
import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import useDetectDesktopWidth from "../../hooks/useDetectDesktopWidth";

const Hero = () => {
  const isDesktopWidth = useDetectDesktopWidth();
  return (
    <Box
      style={{
        height: "95vh",
        backgroundImage: "url('/assets/logtrends.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        display: "flex",
        alignItems: "center",
        justifyContent: isDesktopWidth ? "flex-end" : "center",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            color: "white",
            borderRadius: "4px",
            backgroundColor: "rgba(0, 0, 0, .7)",
            padding: 20,
            maxWidth: isDesktopWidth ? "40vw" : "100vw",
          }}
        >
          <Typography variant="h4" gutterBottom>
            The Future, <Typography variant="inherit">Today</Typography>.
          </Typography>
          <Typography variant="body1">
            We support your mission by providing the highest quality personnel
            and cutting edge technology. Let us worry about staffing, logistics,
            security and more so you can focus on what you do best.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
