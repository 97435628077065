import * as React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";

const LogoLink = ({
  href = "/",
  width,
  style,
  onClick,
}: {
  href?: string;
  width: string;
  style?: React.CSSProperties;
  onClick?(): void;
}) => (
  <Link to={href} style={style || {}} onClick={onClick}>
    <Logo width={width} />
  </Link>
);

export default LogoLink;
