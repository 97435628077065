import { Snackbar } from "@material-ui/core";
import React from "react";

type Props = {
  open: boolean;
  onClose: any;
  anchorOrigin?: any;
  autoHideDuration?: number;
  action?: any;
  message?: string;
  severity: any;
};

const Alert = ({
  open,
  onClose,
  anchorOrigin = { horizontal: "center", vertical: "bottom" },
  autoHideDuration,
  action,
  message,
  severity,
}: Props) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      action={action}
      message={message}
      color={severity}
    ></Snackbar>
  );
};

export default Alert;
