import React, { Component } from "react";
import { Box, Button, Container } from "@material-ui/core";
import LINKS from "../../constants/LINKS";
import LogoLink from "../LogoLink";
import useDetectDesktopWidth from "../../hooks/useDetectDesktopWidth";

const FooterContainer = {
  justifyContent: "space-around",
  alignItems: "center",
  display: "flex",
  width: "100vw",
  padding: 20,
  backgroundColor: "#000",
};

const FooterSectionStyle = ({ isDesktop }: { isDesktop: boolean }) => ({
  flexDirection: isDesktop ? ("row" as "row") : ("column" as "column"),
  display: "flex",
});

const FooterSection = ({
  children,
  isDesktop,
}: {
  children: React.ReactNode;
  isDesktop: boolean;
}) => <Box style={FooterSectionStyle({ isDesktop })}>{children}</Box>;

const Footer = () => {
  const isDesktopWidth = useDetectDesktopWidth();
  return (
    <Box style={FooterContainer}>
      <Container style={FooterContainer}>
        <LogoLink width={isDesktopWidth ? "15vw" : "35vw"} />
        <FooterSection isDesktop={isDesktopWidth}>
          {LINKS.map(({ page, route }) => (
            <Button color="secondary" href={route}>
              {page}
            </Button>
          ))}
        </FooterSection>
      </Container>
    </Box>
  );
};

export default Footer;
