import React, { useEffect, useState } from "react";
import { Box, Button, CssBaseline, ThemeProvider } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/Router";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CookieAlert from "./components/CookieAlert";
// Styling
import ScrollToTop from "./containers/ScrollToTop";
import theme from "./theme";

const App = () => (
  <BrowserRouter>
    <Box>
      <ThemeProvider theme={theme({ dark: false })}>
        <ScrollToTop />
        <CssBaseline />
        <CookieAlert />
        <Navbar />
        <Router />
        <Footer />
      </ThemeProvider>
    </Box>
  </BrowserRouter>
);

export default App;
