import { Box, Container, Typography } from "@material-ui/core";
import * as React from "react";
import HeaderDivider from "../components/HeaderDivider";
import PageHeader from "../components/PageHeader";
import CONTACTS from "../constants/CONTACTS";

const Contact = () => {
  return (
    <Box style={{ minHeight: "94vh" }}>
      <PageHeader
        src={"/assets/intel.jpg"}
        alt="mil"
        title="Contact Us"
        sub="We look forward to hearing from you"
        backgroundPosition="bottom center"
      />
      <HeaderDivider />
      <Container style={{ padding: "30px 20px" }}>
        <Box style={{ margin: 10 }}>
          <Typography variant="h5">Headquarters</Typography>
          <Typography variant="subtitle1">
            Centurion Services, LLC. <br />
            5200 Tennyson Pkwy
            <br /> Plano, TX 75024
          </Typography>
        </Box>
        {CONTACTS.map(({ email, label }) => {
          return (
            <Box style={{ margin: 10 }}>
              <Typography variant="h5">{label}</Typography>
              <Typography variant="subtitle1">
                <a href={`mailto:${email}`}>{email}</a>
              </Typography>
            </Box>
          );
        })}
      </Container>
    </Box>
  );
};

export default Contact;
