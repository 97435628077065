import * as React from "react";

const Logo = ({ width }: { width: string }) => {
  return (
    <img
      style={{ width }}
      src="/assets/Jordy_Trans_1k.png"
      alt="Centurion Logo"
    />
  );
};

export default Logo;
