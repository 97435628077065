const CONTACTS = [
  { email: "inquiries@CenturionServices.biz", label: "General Inquiries" },
  { email: "hr@CenturionServices.biz", label: "Human Resources" },
  { email: "sales@CenturionServices.biz", label: "Sales" },
  { email: "press@CenturionServices.biz", label: "Media Inquiries" },
  { email: "gwac@CenturionServices.biz", label: "GSA and GWAC Inquiries" },
  {
    email: "employmentverification@CenturionServices.biz",
    label: "Employment Verification",
  },
  { email: "careers@CenturionServices.biz", label: "Careers" },
  { email: "general@CenturionServices.biz", label: "Other" },
];

export default CONTACTS;
