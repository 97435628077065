import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import useDetectDesktopWidth from "../../hooks/useDetectDesktopWidth";

const ServiceCard = ({
  header,
  src,
  alt,
  sub,
}: {
  header: string;
  src: string;
  alt: string;
  sub: string;
}) => {
  const isDesktopWidth = useDetectDesktopWidth();
  return (
    <Card
      style={{
        width: isDesktopWidth ? "30%" : "98%",
        margin: 10,
      }}
    >
      <CardContent>
        <Typography variant="h5">{header}</Typography>
        <CardMedia
          style={{
            height: 0,
            paddingTop: "56.25%", // 16:9
            margin: "8px 0",
            borderRadius: 3,
          }}
          image={src}
          title={alt}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{ marginTop: 5 }}
        >
          {sub}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
