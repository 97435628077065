import { Box, Button, CardContent, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { Link } from "react-router-dom";
import HeaderDivider from "../components/HeaderDivider";
import PageHeader from "../components/PageHeader";
import ServiceCard from "../components/ServiceCard";
import SERVICES from "../constants/SERVICES";
import useDetectDesktopWidth from "../hooks/useDetectDesktopWidth";

const Services = () => {
  const isDesktopWidth = useDetectDesktopWidth();
  return (
    <Box style={{ minHeight: "94vh" }}>
      <PageHeader
        src={"/assets/shipping.jpg"}
        alt="services"
        title="Services"
        sub="Supporting Your Mission"
      />
      <HeaderDivider />
      <Box>
        <Grid container justify="center" alignItems="stretch">
          {SERVICES.map(({ header, image: { src, alt }, sub }) => (
            <ServiceCard header={header} src={src} alt={alt} sub={sub} />
          ))}
        </Grid>
        <Box
          style={{
            margin: isDesktopWidth ? 20 : 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              color="textPrimary"
              component="p"
              gutterBottom
              style={{ marginTop: 5 }}
            >
              There is so much more we can do for you, just contact us and we
              can set time to review your team's needs.
            </Typography>

            <Typography
              variant="subtitle1"
              color="textSecondary"
              component="p"
              gutterBottom
              style={{ margin: 10 }}
            >
              Contact us today to find out how we can serve you.
            </Typography>

            <Button variant="contained" color="secondary">
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <Typography color="textPrimary">Contact Us</Typography>
              </Link>
            </Button>
          </CardContent>
        </Box>
      </Box>
    </Box>
  );
};
export default Services;
