import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Divider, SwipeableDrawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useTheme } from "@material-ui/core/styles";
import LogoLink from "../LogoLink";
import LINKS from "../../constants/LINKS";

const MenuStyle = {
  padding: 25,
  flexDirection: "column" as "column",
  display: "flex",
  width: "60vw",
  justifyContent: "space-around",
  height: "75vh",
};

const FullHeightAndWidth = {
  height: "100%",
  width: "100%",
};

const InnerMenuStyle = ({ primary }: { primary: string }) => ({
  height: "100vh",
  backgroundColor: primary,
});

const LinkStyle = ({ secondary }: { secondary: string }) => ({
  ...FullHeightAndWidth,
  fontWeight: "bold" as "bold",
  textDecoration: "none",
  color: secondary,
});

const MenuDrawer = ({
  anchor,
}: {
  anchor: "left" | "top" | "right" | "bottom" | undefined;
}) => {
  const {
    palette: {
      primary: { main: primary },
      secondary: { main: secondary },
      common: { white },
    },
  } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button onClick={toggleDrawer} color="secondary">
        <MenuIcon />
      </Button>
      <SwipeableDrawer
        anchor={anchor}
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <Box style={InnerMenuStyle({ primary })}>
          <Box style={MenuStyle}>
            <LogoLink width={"100%"} href="/" onClick={toggleDrawer} />
            {/* <Divider color={white} /> */}
            {LINKS.map(({ page, route }) => (
              <>
                <Button color="secondary" style={FullHeightAndWidth}>
                  <Link
                    onClick={toggleDrawer}
                    key={page}
                    style={LinkStyle({ secondary })}
                    to={route}
                  >
                    {page}
                  </Link>
                </Button>
                {/* <Divider color={white} /> */}
              </>
            ))}
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default MenuDrawer;
