import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import About from "../../pages/About";
import Careers from "../../pages/Careers";
import Contact from "../../pages/Contact";
import Home from "../../pages/Home";
import Services from "../../pages/Services";

const Router = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" exact component={About} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/services" exact component={Services} />
      <Route path="/careers" exact component={Careers} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Router;
