const LINKS = [
  {
    page: "About",
    route: "/about",
  },
  {
    page: "Services",
    route: "/services",
  },
  {
    page: "Contact",
    route: "/contact",
  },
  {
    page: "Careers",
    route: "/careers",
  },
];

export default LINKS;
