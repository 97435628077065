import { AppBar, Box, Button, Toolbar } from "@material-ui/core";
import * as React from "react";
import { Link } from "react-router-dom";
import MenuDrawer from "../MenuDrawer";
import useDetectDesktopWidth from "../../hooks/useDetectDesktopWidth";
import LINKS from "../../constants/LINKS";
import Logo from "../Logo";
import { useTheme } from "@material-ui/core/styles";
import LogoLink from "../LogoLink";

const Navbar = () => {
  const {
    palette: {
      secondary: { main: secondary },
    },
  } = useTheme();
  const isDesktopWidth = useDetectDesktopWidth();
  const links = LINKS.map(({ page, route }) => (
    <Button color="secondary">
      <Link
        key={page}
        style={{ fontWeight: "bold", textDecoration: "none", color: secondary }}
        to={route}
      >
        {page}
      </Link>
    </Button>
  ));
  return (
    <AppBar
      position="sticky"
      style={{ borderBottom: `2px solid ${secondary}` }}
    >
      <Toolbar>
        <LogoLink width={isDesktopWidth ? "15vw" : "35vw"} />
        {/* Hide Links in Top bar when on mobile */}
        <Box style={{ flexGrow: 1 }} />
        {isDesktopWidth ? links : <MenuDrawer anchor="left" />}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
