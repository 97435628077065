const SERVICES = [
  {
    header: "Personnel",
    image: {
      src: "/assets/personnel.jpg",
      alt: "space",
    },
    sub:
      "We keep a variety of personnel from language specialists to security experts on retainer to ensure we can get your project started on day one.",
  },
  {
    header: "Logistics",
    image: {
      src: "/assets/shipping.jpg",
      alt: "space",
    },
    sub:
      "We handle projects of all sizes and scopes - from a small team to a complex organization, we can help you manage your requirements and meet your needs.",
  },
  {
    header: "Security",
    image: {
      src: "/assets/secure.jpeg",
      alt: "space",
    },
    sub:
      "Whether your adversaries are online or offline, we can help you create a security plan or help recover from an incident.",
  },
  {
    header: "Intelligence",
    image: {
      src: "/assets/intel.jpg",
      alt: "space",
    },
    sub:
      "We were born in the information age and understand that timely, relevant intelligence is core to effective decision making. Our intelligence services provide quality vetted data to better your outcomes.",
  },
  {
    header: "IT Support",
    image: {
      src: "/assets/watchcenter.jpg",
      alt: "space",
    },
    sub:
      "Technology is at the core of what we do. We provide IT professionals that can assist you in selecting and maintaining your devices.",
  },
  // {
  //   header: "Service Name",
  //   image: {
  //     src: "/assets/space.jpeg",
  //     alt: "space",
  //   },
  //   sub: "This is a bunch of info about this service",
  // },
];

export default SERVICES;
