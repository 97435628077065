import { useEffect, useState } from "react";

const useDetectDesktopWidth = (minWidth = 700) => {
  const [isDesktopWidth, setDesktopWidth] = useState(
    window.matchMedia(`(min-width:${minWidth}px)`).matches
  );

  useEffect(() => {
    const listener = () =>
      setDesktopWidth(window.matchMedia(`(min-width:${minWidth}px)`).matches);

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [minWidth]);

  return isDesktopWidth;
};

export default useDetectDesktopWidth;
