import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { purple, green, red } from "@material-ui/core/colors";

const baseTheme = ({ dark }: { dark: boolean }) =>
  createMuiTheme({
    palette: {
      type: dark ? "dark" : "light",
      primary: {
        main: "#000",
        contrastText: "#fefefe",
      },
      secondary: {
        main: "#79aad9",
        // contrastText: "#fefefe",
      },
      error: {
        main: red[500],
      },
      warning: {
        main: purple[500],
      },
      info: {
        main: purple[500],
      },
      success: {
        main: green[500],
      },
      tonalOffset: 0.2,
    },
  });

const theme = (props: any) => responsiveFontSizes(baseTheme(props));

export default theme;
