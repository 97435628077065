import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import * as React from "react";
import { Link } from "react-router-dom";
import Hero from "../components/Hero";
import HeaderDivider from "../components/HeaderDivider";
import ServiceCard from "../components/ServiceCard";
import useDetectDesktopWidth from "../hooks/useDetectDesktopWidth";
import SERVICES from "../constants/SERVICES";

const Home = () => {
  const isDesktopWidth = useDetectDesktopWidth();
  const {
    palette: {
      primary: { main: primary },
    },
  } = useTheme();
  return (
    <Box>
      <Hero />
      <HeaderDivider />
      <Box>
        <Grid
          container
          justify="center"
          alignItems="stretch"
          style={{ marginTop: -25 }}
        >
          {SERVICES.map(({ header, image: { src, alt }, sub }) => (
            <ServiceCard header={header} src={src} alt={alt} sub={sub} />
          ))}
        </Grid>
        <Divider variant="inset" style={{ margin: 10 }} />

        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection={isDesktopWidth ? "row" : "column"}
          style={{ padding: 20, backgroundColor: "" }}
        >
          <img
            alt="go anywhere"
            src="/assets/mil.jpg"
            width={isDesktopWidth ? "50%" : "99%"}
            style={{ border: "5px solid #79aad9" }}
          />
          <Box
            style={{ padding: isDesktopWidth ? 50 : 10 }}
            display="flex"
            flexDirection={isDesktopWidth ? "column" : "column"}
            justifyContent="center"
          >
            <Typography variant="h4" style={{ color: "#79aad9" }}>
              Never Go Alone
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Whether it is a few miles or on the other side of the world, we
              will go with you where ever necessary to help you achieve your
              goals.{" "}
            </Typography>
          </Box>
        </Box>
        <Divider variant="inset" style={{ margin: 10 }} />
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection={isDesktopWidth ? "row" : "column"}
          style={{ padding: 20, backgroundColor: "" }}
        >
          <Box
            style={{ padding: isDesktopWidth ? 50 : 10 }}
            display="flex"
            flexDirection={isDesktopWidth ? "column" : "column"}
            justifyContent="center"
          >
            <Typography variant="h4" style={{ color: "#79aad9" }}>
              Get Going Now
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Stop wasting time on vetting and hiring and let our seasoned
              professionals help get you up and running.
            </Typography>
          </Box>
          <img
            alt="get going"
            src="/assets/plane.jpg"
            width={isDesktopWidth ? "50%" : "99%"}
            style={{ border: "5px solid #79aad9" }}
          />
        </Box>
        <Divider variant="inset" style={{ margin: 10 }} />
        <Box style={{ margin: 10, display: "flex", justifyContent: "center" }}>
          <Box
            style={{
              margin: isDesktopWidth ? 20 : 0,
              width: isDesktopWidth ? "50%" : "100%",
              textAlign: "center",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                color="textPrimary"
                component="p"
                gutterBottom
                style={{ marginTop: 5 }}
              >
                We won't stop working for you until your mission is complete.
              </Typography>

              <Typography
                variant="subtitle1"
                color="textSecondary"
                component="p"
                gutterBottom
                style={{ margin: 10 }}
              >
                Contact us today to find out how we can serve you.
              </Typography>

              <Button variant="contained" color="secondary">
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: primary }}
                >
                  Contact Us
                </Link>
              </Button>
            </CardContent>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
