import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import Alert from "../Alert";

const CookieAlert = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("cookie")) return;
    setIsOpen(true);
  }, []);

  const message =
    "This website uses cookies to enhance user experience and to analyze performance and traffic on our website. ";

  const setCookie = (accept: boolean) => {
    localStorage.setItem("cookie", accept.toString());
    setIsOpen(false);
  };

  const action = (
    <>
      <Button color="secondary" onClick={() => setCookie(true)}>
        Accept
      </Button>
      <Button style={{ color: "#fff" }} onClick={() => setCookie(false)}>
        Decline
      </Button>
    </>
  );
  return (
    <Alert
      open={isOpen}
      severity="info"
      onClose={() => setIsOpen(false)}
      message={message}
      action={action}
    ></Alert>
  );
};

export default CookieAlert;
