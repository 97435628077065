import { Box, Typography } from "@material-ui/core";
import React from "react";

const PageHeader = ({
  src,
  alt,
  title,
  sub,
  backgroundPosition = "center center",
}: {
  src: string;
  alt: string;
  title: string;
  sub: string;
  backgroundPosition?: string;
}) => {
  return (
    <Box
      style={{
        backgroundImage: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition,
        height: "45vh",
      }}
    >
      <Box
        style={{
          backgroundColor: "rgba(0,0,0,.6)",
          color: "white",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" style={{ color: "#cecece" }} gutterBottom>
          {sub}
        </Typography>
      </Box>
    </Box>
  );
};

export default PageHeader;
