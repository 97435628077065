import { Box, Container, Typography } from "@material-ui/core";
import * as React from "react";
import HeaderDivider from "../components/HeaderDivider";
import PageHeader from "../components/PageHeader";
import useDetectDesktopWidth from "../hooks/useDetectDesktopWidth";

const About = () => {
  const isDesktopWidth = useDetectDesktopWidth();
  return (
    <Box style={{ minHeight: "94vh" }}>
      <PageHeader
        src={"/assets/JordyBlack_Square_1k.png"}
        alt="mil"
        title="About Us"
        sub="Serving Proudly Since 2015"
      />
      <HeaderDivider />
      <Container
        style={{
          padding: "50px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: isDesktopWidth ? "70vw" : "90vw",
          maxWidth: "1200px",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Our History
        </Typography>
        <Typography gutterBottom variant="body1">
          At Centurion Services, we are powering progress throughout the world,
          helping our clients with their most complex projects and programs. No
          matter the mission, no matter the environment, we deliver. Founded in
          2015 in beautiful Plano, TX, we partner with government and industry
          clients to provide purposeful and comprehensive solutions with an
          emphasis on efficiency and safety. With a full portfolio of services,
          proprietary technologies and expertise, our employees are ready to
          handle projects and missions from planning and design to
          sustainability and maintenance. We promote a culture of safety,
          accountability and integrity in every project, program and community
          we serve. No matter where they are, our clients trust us to deliver
          the impossible on a daily basis.
        </Typography>
        <img
          alt="Plano, Tx"
          width="90%"
          style={{ margin: 40, maxWidth: "50vw" }}
          src="/assets/plano.jpg"
        />
        <Typography variant="h5" gutterBottom>
          Our Future
        </Typography>
        <Typography gutterBottom variant="body1">
          Centurion is always growing and evolving. As global demands change and
          markets transform, we'll be at the forefront, leading the way with
          innovative solutions and services that will solve tomorrow's
          challenges and define progress for years to come.
        </Typography>
      </Container>
    </Box>
  );
};

export default About;
